import React from 'react'
import FindYour from './FindYour'
import TailoredLiving from './TailoredLiving'
import Contact from './Contact'

const Home = () => {
  return (
    <div className='App'>
     <FindYour/>
     <TailoredLiving/>
    <Contact/>
    </div>
  )
}

export default Home
