import React from 'react'
import TranformYour from './TranformYour'
import FlexibleManage from './FlexibleManage'
import Contact from '../Home/Contact'

const Owners = () => {
  return (
    <div>
      <TranformYour/>
      <FlexibleManage/>
      <Contact/>
    </div>
  )
}

export default Owners
